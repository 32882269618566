var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",attrs:{"id":"page-login"}},[_c('div',{staticClass:"vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4"},[_c('vx-card',[_c('div',{staticClass:"full-page-bg-color",attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"vx-row no-gutter justify-center items-center"},[_c('div',{staticClass:"vx-col hidden lg:block lg:w-1/2"},[_c('img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/images/pages/login.png"),"alt":"login"}})]),_c('div',{staticClass:"vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg"},[_c('div',{staticClass:"p-8 login-tabs-container"},[_c('div',{staticClass:"vx-card__title mb-4"},[_c('h4',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t('login.page.title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('login.page.message')))])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"loginForm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.loginJWT)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('login.form.username'),"rules":"required|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"username","icon-no-border":"","icon":"icon icon-user","icon-pack":"feather","label-placeholder":_vm.$t('login.form.username')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('login.form.password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-6",attrs:{"type":"password","name":"password","icon-no-border":"","icon":"icon icon-lock","icon-pack":"feather","label-placeholder":_vm.$t('login.form.password')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"flex flex-wrap justify-between my-5"},[_c('vs-checkbox',{staticClass:"mb-3",model:{value:(_vm.checkbox_remember_me),callback:function ($$v) {_vm.checkbox_remember_me=$$v},expression:"checkbox_remember_me"}},[_vm._v(_vm._s(_vm.$t('login.form.rememberme')))]),_c('router-link',{attrs:{"to":"/forgot/password"}},[_vm._v(_vm._s(_vm.$t('login.form.link.forgot_password')))])],1),_c('div',{staticClass:"flex flex-wrap justify-between mb-3"},[_c('router-link',{staticClass:"ml-1",attrs:{"to":{name:'home'}}},[_c('vs-button',{attrs:{"type":"flat"}},[_vm._v(" "+_vm._s(_vm.$t('login.form.button.back'))+" ")])],1),_c('vs-button',{attrs:{"icon-pack":"feather","icon":"icon-arrow-right","icon-after":"","button":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('login.form.button.login')))])],1)],1)]}}])})],1)])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }