<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">{{ $t('login.page.title') }}</h4>
                  <p>{{ $t('login.page.message') }}</p>
                </div>

                <ValidationObserver v-slot="{ invalid, handleSubmit }">
                  <form ref="loginForm" @submit.prevent="handleSubmit(loginJWT)">

                    <ValidationProvider :name="$t('login.form.username')" rules="required|alpha_num" v-slot="{ errors }">
                      <vs-input
                        name="username"
                        icon-no-border
                        icon="icon icon-user"
                        icon-pack="feather"
                        :label-placeholder="$t('login.form.username')"
                        v-model="username"
                        class="w-full"/>
                      <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>


                    <ValidationProvider :name="$t('login.form.password')" rules="required" v-slot="{ errors }">
                      <vs-input
                        type="password"
                        name="password"
                        icon-no-border
                        icon="icon icon-lock"
                        icon-pack="feather"
                        :label-placeholder="$t('login.form.password')"
                        v-model="password"
                        class="w-full mt-6"/>
                      <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>

                    <div class="flex flex-wrap justify-between my-5">
                      <vs-checkbox v-model="checkbox_remember_me" class="mb-3">{{ $t('login.form.rememberme') }}</vs-checkbox>
                      <router-link to="/forgot/password">{{ $t('login.form.link.forgot_password') }}</router-link>
                    </div>
                    <div class="flex flex-wrap justify-between mb-3">
<!--                      <vs-button type="border" @click="registerUser">{{ $t('login.form.button.register') }}</vs-button>-->
                      <router-link :to="{name:'home'}" class="ml-1">
                        <vs-button type="flat">
                          {{ $t('login.form.button.back') }}
                        </vs-button>
                      </router-link>
                      <vs-button icon-pack="feather" icon="icon-arrow-right" icon-after button="submit" :disabled="invalid">{{ $t('login.form.button.login') }}</vs-button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Log in'
  },
  data () {
    return {
      username: '',
      password: '',
      checkbox_remember_me: false
    }
  },

  methods: {
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: this.$t('login.notify.already_logged.title'),
          text: this.$t('login.notify.already_logged.text'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        this.$router.push({'name': 'home'}).catch(() => {})

        return false
      }
      return true
    },
    loginJWT () {

      if (!this.checkLogin()) return

      // Loading
      this.$vs.loading()

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          username: this.username,
          password: this.password
        }
      }

      this.$store.dispatch('auth/loginJWT', payload)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: this.$t('login.notify.login_fail.title'),
            text: this.$t('login.notify.login_fail.text', { error }),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    registerUser () {
      if (!this.checkLogin()) return
      this.$router.push('/pages/register').catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
  }
}

</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1
    }

    .bg-twitter {
      background-color: #00aaff
    }

    .bg-google {
      background-color: #4285F4
    }

    .bg-github {
      background-color: #333
    }
  }
}
</style>

